import React from 'react';
import styles from "./index.less";

import Section from '../Section/Section';

const Footer = ({ siteTitle }) => (
  <Section className={styles.root}>
    Review Dino &copy; All Rights Reserved 2019
  </Section>
)

export default Footer
