import React from 'react';
import classnames from 'classnames'
import styles from './icons.less';

export const SuccessIcon = () => <svg className={styles.svg} version="1.1" viewBox="0 0 130.2 130.2">
  <circle className={classnames(styles.path, styles.circle)} fill="none" stroke="#73AF55" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
  <polyline className={classnames(styles.path, styles.check)} fill="none" stroke="#73AF55" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
</svg>;

export const ErrorIcon = () => <svg className={styles.svg} version="1.1" viewBox="0 0 130.2 130.2">
  <circle className={classnames(styles.path, styles.circle)} fill="none" stroke="#D06079" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
  <line className={classnames(styles.path, styles.line)} fill="none" stroke="#D06079" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
  <line className={classnames(styles.path, styles.line)} fill="none" stroke="#D06079" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
</svg>;