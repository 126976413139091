import React, { Component, Fragment } from 'react';

import { Mutation } from 'react-apollo';
import JSONInput from 'react-json-editor-ajrm';
import locale    from 'react-json-editor-ajrm/locale/en';
import { Validator } from 'jsonschema';
import _ from 'lodash';
import gql from "graphql-tag";
import getDefaultConfig from './defaultConfig';
import errorMessages from './errorMessages';
import Modal from './modal';
import { schema } from './schema';
import styles from './form.less';

const v = new Validator();

export const UPDATE_CONFIG_MUTATION = gql`
  mutation UpdateConfig($notification: String!, $orgId: String!) {
    updateConfig(
      input: {
        notification: $notification,
        orgId: $orgId,
      }
    ) {
      status
    }
  }
`;

const AlertSvg = () => <svg fill="#86181d" height="16" width="16" className="octicon octicon-issue-opened mx-auto" viewBox="0 0 14 16" version="1.1">
  <path fillRule="evenodd" d="M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"></path>
</svg>;

export default class Editor extends Component {
  state = {
    initialConfig: _.find(this.props.orgConfig, ['team', this.props.team]) || getDefaultConfig(this.props.orgId),
    config: {},
    orgConfig: this.props.orgConfig,
    errors: [],
    isModified: false,
    modalIsOpen: false,
    isConfirmed: false
  };

  confirmConfig = () => () => {
    if (!this.state.isConfirmed) {
      this.setState({
        modalIsOpen: true
      })
    }
  }

  dismissModal = () => () => {
    this.setState({
      modalIsOpen: false
    })
  }

  submitConfig = (mutation) => () => {
    this.setState({
      isModified: false,
      isConfirmed: true
    })

    mutation().then(() => {
      this.setState({
        success: true
      })
    }).catch(error => {
      this.setState({
        success: false,
        error
      })
    });
  }

  onChange = (data) => {
    const { jsObject } = data;

    const { errors } = v.validate(jsObject, schema);

  
    this.setState({ errors });

    if (errors.length > 0 || _.isEqual(jsObject, this.state.initialConfig)) {
      this.setState({
        isModified: false
      });

      return;
    }    
  
    this.setState({
      config: jsObject,
      isModified: true,
      success: false
    })
  };

  render() {
    const { errors, isModified } = this.state;
    const { orgId } = this.props;

    return (
      <Mutation
        mutation={UPDATE_CONFIG_MUTATION}
        variables={{ notification: JSON.stringify(this.state.config), orgId }}
      >
        {(mutation, { called, loading, data, error }) => {
          const canSubmit = _.isEmpty(errors) && isModified;
          return (<Fragment>
            <div className={styles.form}>
        <Modal
          modalIsOpen={this.state.modalIsOpen}
          error={this.state.error}
          orgId={orgId}
          isConfirmed={this.state.isConfirmed}
          from={JSON.stringify(this.state.initialConfig, null, 1)}
          to={JSON.stringify(this.state.config, null, 1)}
          onConfirm={this.submitConfig(mutation)}
          onDismiss={this.dismissModal()}
      />
        <JSONInput
          id="config"
          locale={locale}
          placeholder ={this.state.initialConfig}
          onChange={this.onChange}
          height="70vh"
          />
        </div>
        {
          !_.isEmpty(errors) && 
            <div className="flash flash-error mt-2">
              {_.map(errors, ({ stack, property }) => {
                const failedProperty = _(property).split('.').last().replace(/\[\d\]/, '');
                
                return (
                <div key={ stack }>
                  <small>
                    <AlertSvg />
                    {' '}
                    {
                      errorMessages[failedProperty] ||
                      _.truncate(_.replace(stack, 'instance.', ''), { length: 70 })
                    }
                  </small>
                </div>
              )})}
            </div>
        }
              <button
                onClick={canSubmit ? this.confirmConfig() : _.noop}
                className={`btn btn-primary btn-large btn-block mt-2 ${canSubmit ? '' : 'disabled'}`}
              >
                Check and Save
              </button></Fragment>
          )}}
      </Mutation>
    );
  }
}
