import moment from 'moment-timezone';
const timezones = moment.tz.names();

export const schema = {
  "type": "object",
  "properties": {
    "team": {
      "type": "string"
    },
    "isActive": {
      "type": "boolean"
    },
    "timeZone": {
      "type": "string",
      "enum": timezones
    },
    "schedule": {
      "type": "array",
      "minItems": 1,
      "items": {
        "type": "object",
        "properties": {
          "day": {
            "type": "string",
            "enum": [
              "weekday",
              "weekend",
              "monday",
              "tuesday",
              "wednesday",
              "thursday",
              "friday",
              "saturday",
              "sunday"
            ]
          },
          "times": {
            "type": "array",
            "minItems": 1,
            "items": {
              "type": "string",
              "pattern": "^([01][0-9]|2[0-3]):(00|30)$"
            }
          }
        },
        "additionalProperties": false,
        "required": [
          "day",
          "times"
        ]
      }
    },
    "repos": {
      "type": "object",
      "patternProperties": {
        ".*": {
          "type": "array",
          "items": {
            "type": "string",
            "minItems": 1
          },
          "minItems": 1
        }
      },
      "additionalProperties": false
    },
    "slack": {
      "type": "object",
      "properties": {
        "channel": {
          "type": "string",
          "pattern": "^#"
        }
      },
      "additionalProperties": false,
      "required": [
        "channel"
      ]
    }
  },
  "additionalProperties": false,
  "required": [
    "team",
    "repos",
    "slack",
    "timeZone",
    "schedule"
  ]
};