export default (org) => ({
    "team": "my-team-name",
    "isActive": true,
    "schedule": [
        {
            "day": "weekday",
            "times": [
                "09:00",
                "13:30",
                "15:00"
            ]
        }
    ],
    "timeZone": "Australia/Sydney",
    "repos": {
        [org]: [
            "my-repository",
            "my-another-repository"
        ]
    },
    "slack": {
        "channel": "#hey-team"
    }
});
