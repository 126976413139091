import React from 'react'
import classnames from 'classnames'
import styles from "./Button.less";

const Button = ({ secondary, href, children, newTab, className }) => (
  <a target={ newTab ? '_blank' : ''} className={classnames(styles.button, className, secondary ? styles.ghost : styles.solid)} href={href}>
    {children}
  </a>
)

export default Button;
