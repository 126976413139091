import React, { Fragment } from 'react';
import { Query } from "react-apollo";

import _ from 'lodash';
import gql from "graphql-tag";
import { Link } from 'react-router-dom';
import slack from './slack.svg';

const loader = <svg height="48" width="48" className="mt-8 anim-pulse octicon octicon-mark-github d-block mx-auto" viewBox="0 0 16 16" version="1.1" aria-hidden="true"><path fillRule="evenodd" d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0 0 16 8c0-4.42-3.58-8-8-8z"></path></svg>;

const notificationsQuery = gql`
  query notifications($orgId: String!) {
    notifications(orgId: $orgId) {
      notifications
    }
  }
`;

const NotificationsPage = ({ match }) => {
  const { orgId } = match.params;
  return (
    <Fragment>
      <span><Link to={`/installations`}>All orgs</Link> &rarr; All {orgId} notifications</span>

      <Query query={notificationsQuery} variables={{ orgId }}>
      {({ loading, error, data }) => {
        if (loading) return loader;
        if (error) return (<div><br /><br />Hmmm... something went terribly wrong... try signing in and navigating to your config.</div>);
        let parsedNotifications;
        try {
          parsedNotifications = JSON.parse(_.get(data, 'notifications.notifications'));
        } catch (e) {
          return (<div><br/><br/>Hmmm... something went terribly wrong... try signing in and navigating to your config.</div>)
        }

        return (
          <ul className="menu">
            {
              _.map(parsedNotifications, n => (
                <li key={n.team} className="menu-item" style={{ display: 'flex', alignItems: 'center' }}>
                  <img alt="slack" src={slack} style={{ display: 'inline', maxHeight: '30px', maxWidth: '30px' }} aria-label="slack" />
                  <Link to={`${_.replace(window.location.pathname, '/notifications/', '/notification/')}/${n.team}`}>
                    {n.team}
                  </Link><span style={{ flexGrow: '1', textAlign: 'right' }} className={n.isActive === false ? "text-gray" : "text-green"}>&#9679;</span>
                </li>
              ))
            }
            <li className="menu-item d-flex flex-items-center flex-justify-center" key="new">
              <Link to={`${_.replace(window.location.pathname, '/notifications/', '/notification/')}/new`}>
                + Add new notification
              </Link>
            </li>
          </ul>
        );
      }}
    </Query>
    </Fragment>
  )
}

export default NotificationsPage
