import React, { Fragment } from 'react';
import screenshot from "./screenshot.png";
import Button from '../components/Button/Button';
import Section from '../components/Section/Section';
import door from '../icons/door.png';
import money from '../icons/money.png';
import map from '../icons/map.png';
import thumbsup from '../icons/thumbsup.png';
import slack from '../icons/slack.svg';

import styles from './home.less';

const IndexPage = () => (
  <Fragment>
    <Section
      imgSrc={door}
      bgColor="secondary"
      imgAlt="dino opening a door"
      title={<span className={styles.slackTitle}>Smart Alerts <img alt="slack" src={slack} className={styles.slackIcon} aria-label="slack" /></span>}
      text="Save time by automating the painful process of finding open pull requests that require attention. Fully customizable with a setting to suit every workflow."
    >
      <Button className={styles.button} href="https://github.com/apps/review-dino/installations/new">
        Install
      </Button>
    </Section>
    <Section
      reverse
      imgSrc={screenshot}
      imgAlt="slack-screenshot"
      title="Avoid stale PRs"
      text={<span>Configure your Slack notifications and add repos you care about so you never ever have these moments again...<br/><br/>"What was that even about?!"</span>}
    />
    <Section
      bgColor="secondary"
      imgSrc={thumbsup}
      imgAlt="Dino thumbs up"
      title="Risk free installation"
      text={<span>Dino does not need access to code at all.<br />It only monitors activity that is happening around reviews to help your team to be as productive as possible.</span>}
    />
    <Section
      reverse
      imgSrc={map}
      imgAlt="Dino looking at map"
      title="We are here to help"
      text={<span>No matter which issues you are experiencing, we are always around. Have a look at the documentation, or if you have any questions, just <a className={styles.email} href="mailto:hello.reviewdino@gmail.com">email us</a>.
      </span>}
    >
      <Button className={styles.button} secondary newTab href="https://reviewdino.github.io/">
        Documentation
      </Button>
    </Section>
    <Section
      bgColor="secondary"
      imgSrc={money}
      imgAlt="Dino money"
      title="So... how much?"
      text={'It\'s absolutely FREE, because dino has not grown up yet, but we would appreciate if you share the goodness with friends and provide your feedback, ping us if you desperately need some features.'}
    />
  </Fragment>
)

export default IndexPage
