import React, { Fragment } from 'react';
import Modal from 'react-modal';
import { SuccessIcon, ErrorIcon } from './icons';
import Differ from 'react-differ';

try {
  Modal.setAppElement('#app');
} catch (e) {}

const StatusMessage = ({ error, orgId }) => (
  <Fragment>
    { error ? <ErrorIcon /> : <SuccessIcon /> }
    <strong className={`text-${error ? 'red' : 'green'}`}>{error ? 'Bummer! Something went wrong!' : 'You are all set!'}</strong>
    <br />
    <a style={{ display: 'block' }} href={`/installations`}>Go to all orgs</a>
    <a style={{ display: 'block' }} href={`/notifications/${orgId}`}>Go to org notifications</a>
  </Fragment>  
);

export default ({ modalIsOpen, error, orgId, isConfirmed, from, to, onConfirm, onDismiss }) => {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '60%',
      maxHeight: '90%',
      alignItems: 'center',
      ...(isConfirmed ? {
        display: 'flex',
        flexDirection: 'column'
      } : {})
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      style={customStyles}>
      {isConfirmed ? <StatusMessage error={error} orgId={orgId} /> : (
        <Fragment>
          <div className="box border" style={{ overflow: 'scroll', maxHeight: '70vh' }}>
            <Differ from={from} to={to} />
          </div>
          <div className="d-flex mt-2">
            <button
              style={{ flexGrow: 1 }}
              onClick={onConfirm}
              className="btn btn-primary btn-large mr-2"
            >
              Yup, all good!
            </button>
            <button
              styles={{ display: 'inline' }}
              onClick={onDismiss}
              className="btn btn-large"
            >
              No, let me change it...
            </button>
          </div>
        </Fragment>)
      }
    </Modal>
  );
}
