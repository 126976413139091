import React, { Fragment } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import dino from "./dino.png";
import dinoText from '../icons/logo-text.png';
import qs from 'qs';
import gql from "graphql-tag";
import { Query } from "react-apollo";
import styles from "./header.less";
import Button from './Button/Button';
import Section from './Section/Section';

const queryParams = {
  client_id: 'Iv1.c7900d8b0b671984',
  return_url: `${window.location.href}/settings`,
  scope: 'user',
};

const userQuery = gql`
  query User {
    user {
      user
    }
  }
`;

const Header = ({ siteTitle }) => (
  <div className={styles.root}>
    <Section className={styles.container}>
      <img alt="Review Dino Logo" src={dino} className={styles.logo} />
      <img alt="Review Dino Text" src={dinoText} className={styles.appName} />
      <div className={classnames('d-flex', 'flex-items-center', 'flex-justify-end')} style={{ flexGrow: '1' }}>
        <Query query={userQuery}>
          {({ loading, error, data }) => {
            const user = _.get(data, 'user.user', '{}');
            const parsedUser = JSON.parse(user);
            
            return (<Fragment>
              {
                _.isEmpty(data) ?
                  <Button secondary href={`https://github.com/login/oauth/authorize?${qs.stringify(queryParams)}`}>
                    Sign in
                  </Button> :
                  <div>
                    {parsedUser.login}
                    <img alt="github user avatar" src={parsedUser.avatar_url} className={classnames('avatar', 'ml-2', styles.ava)} />
                  </div>
              }</Fragment>
            )
          }}
        </Query>
      </div>
    </Section>
  </div>
)

export default Header
