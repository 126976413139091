import React, { Fragment } from 'react'
import classnames from 'classnames'
import styles from "./Section.less";

const Section = ({ reverse, title, text, imgSrc, imgAlt, children, className, bgColor }) => {
  const Image = () => (
    <div className={styles.imageContainer}>
      <img src={imgSrc} alt={imgAlt} className={styles.image} />
    </div>
  );

  return (
    <div className={classnames({ [styles[`${bgColor}Bg`]]: bgColor, [styles.reverse]: reverse })}>
      <div className={classnames(styles.root, className, imgSrc ? styles.large : styles.container)}>
        {imgSrc ?
          <Fragment>
            {reverse && <Image />}
            <div className={styles.content}>
              <h1 className={styles.heading}>{title}</h1>
              <p className={styles.subheading}>{text}</p>
              {children}
            </div>
            {!reverse && <Image />}
          </Fragment> :
          children
        }
      </div>
    </div>
  )
}

export default Section;
