import React, { Component } from 'react';
import { ApolloProvider } from 'react-apollo';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Header from './components/header';
import Footer from './components/Footer';
import Setup from './pages/setup';
import Home from './pages/home';
import Installations from './pages/installations';
import Notifications from './pages/notifications';
import Notification from './pages/notification';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import cookie from "cookie";
import styles from "./App.less";

const isProduction = process.env.NODE_ENV === 'production';
const uri = `${isProduction ? 'https://gbb3ib5tgi.execute-api.ap-southeast-2.amazonaws.com/dev' : 'http://localhost:8000'}/graphql`;

const httpLink = createHttpLink({ uri });

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const { dinoSesh } = cookie.parse(document.cookie);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'dino-session': dinoSesh ? dinoSesh : "",
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

class App extends Component {
  render() {
    return (
      <ApolloProvider client={client}>
        <div className={styles.body}>
          <Header />
          <div className={styles.content}>
            <Router>
              <div>
                <Route exact path="/" component={Home} />
                <Route path="/setup" component={Setup} />
                  <div className="container-md">
                    <div className="two-thirds column centered">
                      <Route exact path="/installations" component={Installations} />
                      <Route path="/notifications/:orgId" component={Notifications} />
                      <Route path="/notification/:orgId/:team" component={Notification} />
                    </div>
                  </div>
              </div>
            </Router>
          </div>
          <Footer />
        </div>
      </ApolloProvider>
    );
  }
}

export default App;
